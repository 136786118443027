

















































































































































































































































































































  .divcolor{
      background-color: #5cadff;
  }

  .top{
      padding: 10px;
      background: rgba(0, 153, 229);
      color: #fff;
      text-align: center;
      font-size:10px;
      border-radius: 2px;
  }  
  .el-header  {
      background-color: #fff;
      color: #409EFF;
      text-align: center;
      line-height: 60px;
      width:100%;
  }
.el-main {
  background-color: #fff;
  color: rgb(207, 206, 206);
  text-align: center;
  height:100%;
}
.el-icon-arrow-down {
  font-size: 10px;
}
.menu-div{
    width:140px; 
    text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
    overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
    white-space:nowrap; //禁止换行
    height:40px;
    line-height:40px;
}
.img-div{
    text-overflow:ellipsis; /*对超出容器的部分强制截取，高度不确定则换行*/
    overflow:hidden; /*显示省略符号来代表被修剪的文本。*/
    white-space:nowrap; //禁止换行,文本溢出显示省略号，要显示全内容在DIV上增加title属性
    line-height:20px;
}
.group-css{
    display:flex;
    justify-content:flex-start;
    flex-wrap:wrap;
    width:1246px;
}
 .footer-css {
      width: 100%;
      position: relative;
      bottom: 0 ;
      background:#f8f8f9;
      height:360px !important;
      vertical-align: center;
      text-align: center;
      color:#409EFF;
      font-weight: 600;
      font-size:12px;
  } 
  .card-div {
      padding: 14px;
      background:#F2F6FC; 
      text-align:middle;
  }
  .cardhover:hover{  
      border :#409EFF 1px solid;
  }
  .topback{
      border:1px solid #E4E7ED; 
      width:60px;
      height:60px;
      font-size:12px;
      color: #409EFF;
      text-align: center;    
      display:flex;
      flex-direction: column;
      position:fixed;
      right:0px;
      bottom:275px;
      background-color: rgb(255,255,253);
      cursor:pointer;
      z-index:999999;
  }
  .topfont{
      color:#409EFF;
      font-size:16px;
      // font-weight:600;
  }
  .topback:hover{
      background-color:#2b85e4;
      color:white;
  }
  
