



































































































































































































































































































































































































































































































































































































































































.el-carousel {  
    .el-carousel__button {// 指示器按钮
        width: 14px;
        height: 14px;
        border: none;
        border-radius: 50%;
        background-color: rgb(9, 143, 226);
    }
    .is-active .el-carousel__button {// 指示器激活按钮
        background: #e9378a;
    }
}
.atooltip{
  border: #1480ce 1px solid !important;
}

.el-tooltip__popper[x-placement^=bottom] .popper__arrow{
    border-right-color: #1480ce !important;
    border-left-color: #1480ce !important;
}
.el-col:hover  {  
    background: #409eff!important; 
} 
.el-dropdown-menu{

    padding-left:0px !important; 
    margin-right:-48px !important; 
    margin-top:50px !important;   
}
.el-submenu__title:focus, .el-submenu__title:hover  {  
    background: #dcdee2!important; 
    width:100% !important;
    line-height:35px;
    border-left:3px solid #409eff;
}  
.el-menu-item:hover{  
    /* background: #2db7f5 !important;  */
    color: #fff !important; 

}  
.el-menu-item.is-active {  
    color: #fff !important;  
    /* background: #409EFF !important;   */
}     
.el-menu--collapse  {
    width:200px;
    line-height:35px;
}  
.el-submenu__title i {
  color: #5cadff;
}
.el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
}    
.el-dialog__body{
    padding: 10px;
}
.el-dialog__header{
    padding: 0px;
}
.el-dialog__footer{
    padding: 5px;
    border-top:1px solid #ccc;
} 
.el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}    
.askcss{
  margin-left:1px;
  position: fixed;
  bottom:20px;
  color:#3B170B;
  border: 2px solid #c0c4cc;
  background-color: white;
  border-radius: 5px;
  z-index:999999;
}
.el-textarea__inner{
    resize:none;  /*el-input textarea 隐藏放大按钮 */
}
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #e8eff5;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(149, 204, 248);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(149, 204, 248);
}

::-webkit-scrollbar-thumb:active {
    background-color: rgb(149, 204, 248);
}
.el-table__body-wrapper {
&::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    }
    &::-webkit-scrollbar-track {
    background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    }
    &::-webkit-scrollbar-thumb:hover {
    background-color: #409eff;
    }
}
